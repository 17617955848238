<template>
  <div
    class="modal fade"
    id="modalPengeluaran"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Pengeluaran</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label"
                >Akun Perkiraan
              </label>
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Masukkan Akun Perkiraan"
                v-model="formModalPengeluaran.nama"
              />
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Item</label>
              <div class="w-100">
                <Select2
                  v-model="formModalPengeluaran.id_rab"
                  :disabled="!isEditable"
                  :options="optionRAB"
                  placeholder="Pilih Item..."
                  id="selectRab"
                  @change="formChangePengeluaran('id_rab', $event)"
                  @select="formChangePengeluaran('id_rab', $event)"
                  :settings="{ templateResult: formatState }"
                  :class="{
                    'is-invalid': formErrorModal && formErrorModal.id_rab,
                  }"
                />
                <input
                  type="text"
                  class="form-control mt-3"
                  v-model="formModalPengeluaran.nama_item"
                  placeholder="Masukan nama item"
                  v-if="formModalPengeluaran.id_rab == 'other'"
                />
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Nilai </label>
              <div class="w-100">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Rp</div>
                  </div>
                  <input
                    type="text"
                    class="form-control text-right"
                    :readonly="!isEditable"
                    id="inlineFormInputGroupUsername"
                    placeholder="Masukan Nilai"
                    @keyup="formChangePengeluaran('nilai', $event)"
                    :model-modifiers="{ number: true }"
                    v-money="configMoney"
                    v-model.lazy="formModalPengeluaran.nilai"
                    :class="{
                      'is-invalid': formErrorModal && formErrorModal.nilai,
                    }"
                  />
                </div>
                <div
                  v-if="formModalPengeluaran.sisa"
                  :style="{
                    fontSize: '13px',
                  }"
                  class="text-info mx-2"
                >
                  Sisa nilai item : {{ formModalPengeluaran.sisa }}
                </div>
                <div
                  class="form-error"
                  v-if="formErrorModal && formErrorModal.nilai"
                >
                  {{ formErrorModal.nilai }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="saveDetail"
              :disabled="!isEditable"
              class="btn btn-save"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Pengeluaran</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-project">Project </router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{ name: 'DetailProject', params: { kode: kode } }"
                      >Detail Project</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }}
                  </li>
                </ol>
              </nav>
            </div>

            <div>
              <button
                class="btn btn-save"
                :disabled="isSubmit"
                v-if="isEditable"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>
          <div class="card">
            <div class="row mt-3">
              <div class="col-6">
                <div class="title">Detail Pengeluaran Project</div>
                <div class="sub-title">Edit informasi yang diperlukan</div>
              </div>
            </div>
            <hr />
            <div class="row mt-3">
              <div class="col-12" v-if="msgAlert">
                <div class="alert alert-info" :style="{ fontSize: '15px' }">
                  <h4 class="alert-heading" :style="{ fontSize: '17px' }">
                    Perhatian!
                  </h4>
                  <p class="mb-0">
                    {{ msgAlert }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Tanggal</label>
                  <Datepicker
                    :autoApply="true"
                    v-model="formPengeluaran.tanggal"
                    :class="{ 'is-invalid': formError && formError.tanggal }"
                    placeholder="Tanggal"
                    :format="format"
                    :enableTimePicker="false"
                    :disabled="!isEditable"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    @update:modelValue="changeDate($event, 'tanggal')"
                    :openMenuOnFocus="true"
                    noToday
                  ></Datepicker>
                  <div class="form-error" v-if="formError && formError.tanggal">
                    {{ formError.tanggal }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Penanggung Jawab</label>
                  <Select2
                    v-model="formPengeluaran.id_karyawan"
                    :options="optionPenanggungJawab"
                    placeholder="Pilih Penanggung jawab..."
                    @change="formChange('id_karyawan')"
                    :disabled="!isEditable"
                    @select="formChange('id_karyawan')"
                    :class="{
                      'is-invalid': formError && formError.id_karyawan,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_karyawan"
                  >
                    {{ formError.id_karyawan }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Kas/Bank & Hutang</label>
                  <Select2
                    v-model="formPengeluaran.akun_beban"
                    :options="OptionAccountKasBank"
                    :class="{ 'is-invalid': formError && formError.akun_beban }"
                    placeholder="Pilih akun perkiraan..."
                    :settings="{
                      templateResult: formatState,
                    }"
                    @change="formChange('akun_beban', $event)"
                    @select="formChange('akun_beban', $event)"
                    :disabled="!isEditable"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.akun_beban"
                  >
                    {{ formError.akun_beban }}
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-12"
                v-if="formPengeluaran.id_type != 8"
              >
                <div class="form-group">
                  <label>Nomor Referensi</label>
                  <input
                    type="text"
                    placeholder="Masukkan nomor referensi"
                    :class="{
                      'is-invalid': formError && formError.nomor_referensi,
                    }"
                    v-model="formPengeluaran.nomor_referensi"
                    @keyup="formChange('nomor_referensi')"
                    class="form-control"
                    :disabled="!isEditable"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.nomor_referensi"
                  >
                    {{ formError.nomor_referensi }}
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-12"
                v-if="formPengeluaran.id_type == 8"
              >
                <div class="form-group">
                  <label>Jatuh Tempo :</label>
                  <Datepicker
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formPengeluaran.jatuh_tempo"
                    placeholder="Tanggal Mulai"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    @update:modelValue="changeDate($event, 'jatuh_tempo')"
                    :disabled="!isEditable"
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.jatuh_tempo"
                  >
                    {{ formError.jatuh_tempo }}
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-12"
                v-if="formPengeluaran.id_type == 8"
              >
                <div class="form-group">
                  <label>Vendor :</label>
                  <Select2
                    v-model="formPengeluaran.id_vendor"
                    :options="optionVendor"
                    :disabled="!isEditable"
                    :class="{ 'is-invalid': formError && formError.id_vendor }"
                    placeholder="List Client  "
                    @change="formChange('id_vendor')"
                    @select="formChange('id_vendor')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_vendor"
                  >
                    {{ formError.id_vendor }}
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-12"
                v-if="formPengeluaran.id_type != 8"
              >
                <div class="form-group">
                  <label>Catatan</label>
                  <textarea
                    v-model="formPengeluaran.keterangan"
                    :class="{ 'is-invalid': formError && formError.keterangan }"
                    placeholder="Masukkan catatan"
                    rows="3"
                    class="form-control"
                    :disabled="!isEditable"
                  ></textarea>
                  <div
                    class="form-error"
                    v-if="formError && formError.keterangan"
                  >
                    {{ formError.keterangan }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <div class="title">Rincian Pengeluaran Project</div>
                <div class="sub-title">Rincian yang ada pada pengeluaran</div>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between">
              <div class="search">
                <div class="form-group" style="width: 400px">
                  <Select2
                    v-model="AccountPengeluaran"
                    :options="OptionAccountEstimated"
                    placeholder="Pilih akun perkiraan..."
                    :settings="{
                      templateResult: formatState,
                    }"
                    :disabled="!isEditable"
                    @change="myChangeEvent($event)"
                    @select="changePengeluaranAkun($event)"
                  />
                </div>
              </div>
              <div class="card-total">
                <div class="total-debit d-flex flex-column">
                  <span>Nilai</span>
                  <span class="ml-auto">
                    Rp {{ formatMoney(formPengeluaran.nilai) }}</span
                  >
                </div>
              </div>
            </div>

            <div v-if="formError && formError.nilai">
              <div
                class="form-error"
                :style="{ marginBottom: '5px', fontSize: '15px' }"
              >
                Masukan Terlebih dahulu rincian pengeluaran
              </div>
            </div>
            <div class="table-responsive mt-4">
              <table
                class="table table-bordered table-striped"
                id="tableDepartment"
              >
                <thead>
                  <tr>
                    <th style="width: 15%">Akun</th>
                    <th style="width: 20%">Nama Item</th>
                    <th style="width: 20%">Nilai</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="formPengeluaran.jurnal_data.length == 0">
                    <td colspan="3" class="text-center">Tidak ada data</td>
                  </tr>
                  <tr
                    v-for="(value, index) in formPengeluaran.jurnal_data"
                    :key="index"
                    @click="
                      editDetail(
                        index,
                        value.akun,
                        value.nama,
                        value.nilai,
                        value.id_rab,
                        value.nama_item,
                        $event
                      )
                    "
                    :style="{ cursor: 'pointer' }"
                  >
                    <td>{{ value.nama }}</td>
                    <td>
                      {{ value.nama_item }}
                    </td>
                    <td
                      class="text-right position-relative"
                      style="padding-right: 40px !important"
                    >
                      {{ formatMoney(value.nilai) }}
                      <img
                        src="/img/avatar/icon-delete.svg"
                        v-if="isEditable"
                        alt=""
                        class="btn-add-row"
                        style="
                          position: absolute;
                          right: 10px;
                          width: 20px;
                          top: 50%;
                          transform: translateY(-50%);
                          cursor: pointer;
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Datepicker from "vue3-date-time-picker";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { ref } from "vue";
import $ from "jquery";
import { get_AkunList } from "../../../actions/akun_perkiraan";
import {
  get_ProjectPengeluaran,
  post_ProjectPengeluaranSave,
} from "../../../actions/project/pengeluaran";
import { cksClient, checkRules, showAlert } from "../../../helper";
import moment from "moment";
import "moment/locale/id";
import { VMoney } from "v-money";
import { maska } from "maska";
import Select2 from "vue3-select2-component";
import {
  get_ProjectAnggota,
  get_ProjectDetail,
} from "../../../actions/project";
import { get_ProjectRabList } from "../../../actions/project/rab";
import { get_ListVendor } from "../../../actions/vendor";

export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
  },

  directives: {
    maska,
    money: VMoney,
  },

  data() {
    return {
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      id_company: cksClient().get("_account").id_company,
      kode: this.$route.params.kode ? this.$route.params.kode : "",
      AccountPengeluaran: "",
      OptionAccountKasBank: [],
      OptionAccountEstimated: [],
      optionPenanggungJawab: [],
      optionVendor: [],
      optionRAB: [],
      onModal: false,
      labelPage: this.$route.params.nomor ? "Edit" : "Tambah",
      formPengeluaran: {
        no_transaksi: this.$route.params.nomor ? this.$route.params.nomor : "",
        kode_project: this.$route.params.kode ? this.$route.params.kode : "",
        id_divisi: "",
        tanggal: "",
        jatuh_tempo: "",
        id_vendor: "",
        akun_beban: "",
        id_type: "",
        id_karyawan: "",
        nomor_referensi: "",
        keterangan: "",
        nilai: 0,
        jurnal_data: [],
        id_pengeluaran: "",
      },
      formError: [],
      rules: {
        tanggal: {
          required: true,
        },
        akun_beban: {
          required: true,
        },
        id_karyawan: {
          required: false,
        },
        nomor_referensi: {
          required: false,
        },
        keterangan: {
          required: false,
        },
        nilai: {
          required: false,
          min: 1,
        },
        id_pengeluaran: {
          required: false,
        },
      },
      formModalPengeluaran: {
        key: "",
        id: "",
        nilai: 0,
        nama: "",
        id_rab: "",
        nama_item: "",
        sisa: 0,
      },
      rulesModal: {
        nilai: {
          max: 0,
          required: true,
        },
        id_rab: {
          required: true,
        },
      },
      formErrorModal: [],
      minDate: "",
      maxDate: "",
      listInvoice: [],
      id_invoice: "",
      showInvoice: false,
      isSubmit: false,
      isEditable: true,

      msgAlert: "",
      accessDepartement: cksClient().get("_account").akses_departement,
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    this.getProject();
    localStorage.setItem("pengeluaranTab", true);
    this.getItem();
    this.getAnggota();
    this.getClient();
    if (this.labelPage == "Edit") {
      this.getData();
    }
  },

  methods: {
    getProject() {
      get_ProjectDetail(
        this.kode,
        (res) => {
          this.formPengeluaran.id_divisi = res.data.id_divisi;
          this.getAkun("1,8", "OptionAccountKasBank");
          this.getAkun("", "OptionAccountEstimated");
        },
        (err) => {
          console.log(err);
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Data project tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataProject",
              });
            },
            onDismiss: () => {
              this.$router.push({
                name: "DataProject",
              });
            },
          });
        }
      );
    },
    getClient() {
      get_ListVendor(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionVendor = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionVendor.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },

    getData() {
      get_ProjectPengeluaran(
        this.formPengeluaran.no_transaksi,
        (res) => {
          this.formPengeluaran = { ...this.formPengeluaran, ...res.data };
          this.isEditable = false;
          if (res.data.id_reimburse) {
            this.msgAlert = `Data pengeluaran ini berkaitan dengan reimburse ${res.data.id_reimburse}, untuk mengubah data pengeluaran anda bisa melakukannya di detail remburse`;
            this.isEditable = false;
          }
        },
        () => {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Data pengeluaran tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              localStorage.setItem("pengeluaranTab", true);
              this.$router.push({
                name: "DetailProject",
                params: {
                  kode: this.kode,
                },
              });
            },
          });
        }
      );
    },
    getAkun(tipe, keyData) {
      var id_divisi =
        this.accessDepartement && this.formPengeluaran.id_divisi
          ? this.formPengeluaran.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                id_tipe: element.id_tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          // console.log(data);
          this[keyData] = data;
        }
      );
    },
    getAnggota() {
      get_ProjectAnggota(
        {
          id_company: this.id_company,
          kode_project: this.kode,
        },
        (res) => {
          const { list, min, max } = res;
          this.minDate = min ? moment(min).format("YYYY-MM-DD") : "";
          this.maxDate = max ? moment(max).format("YYYY-MM-DD") : "";
          if (this.labelPage != "Edit") {
            this.formPengeluaran.tanggal = this.minDate;
          }
          this.optionPenanggungJawab = list;
        }
      );
    },
    getItem() {
      get_ProjectRabList(this.kode, (res) => {
        const { list } = res;
        this.optionRAB.push({
          id: "other",
          text: "Nama item baru",
        });
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            if (element.kategori != "man power") {
              this.optionRAB.push({
                id: element.id,
                text: element.nama,
                sub_level: element.sub_level,
                disabled: element.hasChild === 1 ? true : false,
                nilai: element.total_rab,
                sisa: element.sisa_nilai,
                // disabled: element.sisa_nilai > 0 ? false : true,
                max: element.sisa_nilai,
              });
            }
          }
        }
      });
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = "";
      if (state.type && state.no) {
        $state = $(
          '<div class="' +
            padding +
            '">' +
            state.text +
            '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
            state.no +
            "<div>" +
            state.type +
            "</div></div></div>"
        );
      } else {
        $state = $('<div class="' + padding + '">' + state.text + "</div>");
      }
      return $state;
    },
    changeDate(data, key) {
      this.formPengeluaran[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },
    async formChange(key, val) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      if (key == "akun_beban") {
        this.formPengeluaran.id_type = val.id_tipe;
      }
      var check = await checkRules(this.rules, this.formPengeluaran);
      this.formError = check.error;
      return check.success;
    },
    async formChangePengeluaran(key, val) {
      if (this.rulesModal[key]) {
        this.rulesModal[key].changed = true;
      }
      // console.log(val);
      if (key == "id_rab" && val.id != "other") {
        this.rulesModal.nilai.max = this.formatMoney(val.max);
        if (
          !this.formModalPengeluaran.nilai ||
          this.formModalPengeluaran.nilai != "0" ||
          this.formModalPengeluaran.nilai != 0 ||
          this.formModalPengeluaran.nilai != `'0'`
        ) {
          this.formModalPengeluaran.nilai = this.formatMoney(val.nilai);
        }
        this.formModalPengeluaran.sisa = this.formatMoney(val.sisa);
      } else if (key == "id_rab") {
        delete this.rulesModal.nilai.max;
        this.formModalPengeluaran.sisa = 0;
      }
      var check = await checkRules(this.rulesModal, this.formModalPengeluaran);
      this.formErrorModal = check.error;
      return check.success;
    },
    async saveDetail() {
      if (this.formModalPengeluaran.id_rab != "other") {
        this.formModalPengeluaran.nama_item = $(
          "#selectRab option:selected"
        ).text();
      }
      for (const key in this.rulesModal) {
        if (Object.hasOwnProperty.call(this.rulesModal, key)) {
          this.rulesModal[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesModal, this.formModalPengeluaran);
      this.formErrorModal = check.error;
      if (
        ((this.formModalPengeluaran.nilai &&
          this.formModalPengeluaran.nama_item) ||
          (this.formPengeluaran.id_type == 8 &&
            this.formModalPengeluaran.nilai)) &&
        check.success
      ) {
        if (this.formModalPengeluaran.key !== "") {
          this.formPengeluaran.jurnal_data[this.formModalPengeluaran.key] = {
            akun: this.formModalPengeluaran.id,
            nama: this.formModalPengeluaran.nama,
            id_rab: this.formModalPengeluaran.id_rab,
            nama_item: this.formModalPengeluaran.nama_item,
            nilai: this.formModalPengeluaran.nilai.replace(/\./g, ""),
          };
        } else {
          this.formPengeluaran.jurnal_data.push({
            akun: this.formModalPengeluaran.id,
            nama: this.formModalPengeluaran.nama,
            id_rab: this.formModalPengeluaran.id_rab,
            nama_item: this.formModalPengeluaran.nama_item,
            nilai: this.formModalPengeluaran.nilai.replace(/\./g, ""),
          });
        }
        this.countDetail();
        $("#modalPengeluaran").modal("hide");
      }
    },
    editDetail(key, id, nama, nilai, id_rab, nama_item, ev) {
      if (ev.target && ev.target.className === "btn-add-row") {
        this.deleteDetail(key);
      } else if (this.isEditable) {
        this.formModalPengeluaran.key = key;
        this.formModalPengeluaran.id = id;
        this.formModalPengeluaran.nilai = nilai;
        this.formModalPengeluaran.nama = nama;
        this.formModalPengeluaran.id_rab = id_rab;
        this.formModalPengeluaran.nama_item = nama_item;
        $("#modalPengeluaran").modal("show");
      }
    },
    deleteDetail(key) {
      delete this.formPengeluaran.jurnal_data[key];
      this.formPengeluaran.jurnal_data.length -= 1;
      this.countDetail();
    },
    countDetail() {
      var nilai = 0;
      for (const key in this.formPengeluaran.jurnal_data) {
        if (Object.hasOwnProperty.call(this.formPengeluaran.jurnal_data, key)) {
          const element = this.formPengeluaran.jurnal_data[key];
          nilai += parseInt(element.nilai);
        }
      }
      this.formPengeluaran.nilai = nilai;
      this.formChange();
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    changePengeluaranAkun(ev) {
      if (ev.id) {
        this.onModal = true;
        this.formErrorModal = [];
        this.formModalPengeluaran.key = "";
        this.formModalPengeluaran.id = ev.id;
        this.formModalPengeluaran.nilai = "";
        this.formModalPengeluaran.nama = ev.text;
        this.formModalPengeluaran.id_rab = "";
        this.formModalPengeluaran.nama_item = "";
        $("#modalPengeluaran").modal("show");
        setTimeout(() => {
          this.AccountPengeluaran = "";
        }, 500);
      }
    },
    postData() {
      this.isSubmit = true;
      post_ProjectPengeluaranSave(
        this.formPengeluaran,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code == 201
              ? "Data pengeluaran berhasil dicatat"
              : "Data pengeluaran berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              localStorage.setItem("pengeluaranTab", true);
              this.$router.push({
                name: "DetailProject",
                params: {
                  kode: this.kode,
                },
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formPengeluaran);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formPengeluaran.no_transaksi
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.modal-body .form-group:last-child {
  position: relative;
}

.title-item {
  position: relative;
}

.card-modal {
  padding: 20px 36px;
}

.btn-add-title {
  background-color: #d7ffea;
  color: #3cb778;
  font-weight: 500;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #eceff1;
}

.table td,
.table th {
  padding: 0 25px !important;
  vertical-align: middle;
  font-size: 14px;
  border-top: 1px solid #dee2e6;
}

th {
  text-align: center;
}

.title-item {
  background-color: #eceff1;
}

.text {
  max-width: 300px;
  width: 150px;
}

.title-rab {
  background-color: #2e3a59;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.btn-add {
  padding: 10px 10px;
  margin-left: 20px;
}

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.modal-dialog {
  max-width: 500px;
}

.modal-content {
  height: 100%;
  border-radius: 0px;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.modal-content {
  overflow-y: auto;
}

.form-label {
  max-width: 134px;
  width: 200px;
}
</style>
